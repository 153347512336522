



























































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  onMounted,
  onBeforeUnmount
} from '@nuxtjs/composition-api'
import { Variant, VariantType } from '~/models/app/variant'

export default defineComponent({
  props: {
    value: { type: Number, default: 0 },
    variant: {
      type: String as PropType<VariantType>,
      default: Variant.PRIMARY
    },
    showProgress: { type: Boolean, default: false },
    progressPositionRight: { type: Boolean, default: false },
    linear: { type: Boolean, default: false },
    striped: { type: Boolean, default: false },
    animated: { type: Boolean, default: false },
    progressClass: { type: [String, Array], default: null },
    barClasses: { type: [String, Array], default: null }
  },
  setup(props, { emit }) {
    const { variant, value } = toRefs(props)
    const dragging = ref(false)

    const pBarRef = ref<HTMLElement | null>(null)
    onMounted(() => {
      window.addEventListener('mouseup', stopDrag, false)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('mouseup', stopDrag, false)
    })

    const variantBgColor = computed(() => {
      switch (variant.value) {
        case Variant.SECONDARY: {
          return 'tw-bg-grey-700'
        }
        case Variant.INFO: {
          return 'tw-bg-sky'
        }
        case Variant.SUCCESS: {
          return 'tw-bg-green'
        }
        case Variant.WARNING: {
          return 'tw-bg-yellow'
        }
        case Variant.DANGER: {
          return 'tw-bg-red'
        }
        case Variant.WHITE: {
          return 'tw-bg-white'
        }
        case Variant.LIGHT: {
          return 'tw-bg-grey-300'
        }
        case Variant.PRIMARY:
        default: {
          return 'tw-bg-primary'
        }
      }
    })
    const variantTextColor = computed(() => {
      if (variant.value === Variant.WHITE) {
        return 'text-current'
      } else if (variant.value === Variant.LIGHT) {
        return 'tw-text-grey-700'
      }
      return 'tw-text-white'
    })

    function width() {
      if (value.value > 100) {
        return '100%'
      }
      return `${value.value}%`
    }

    const progressStyle = computed(() => {
      return {
        width: width()
      }
    })

    function handleProgressChange(e: Event | TouchEvent) {
      const rect = pBarRef.value.getBoundingClientRect()

      emit(
        'selected-progress',
        calcProgress(
          (e.targetTouches ? e.targetTouches[0].pageX : e.pageX) - rect.left,
          pBarRef.value.offsetWidth
        )
      )
    }

    function startDrag() {
      dragging.value = true
      window.addEventListener('mousemove', doDrag, false)
    }

    function doDrag(e: MouseEvent) {
      if (dragging.value) {
        const rect = pBarRef.value.getBoundingClientRect()
        emit(
          'selected-progress',
          calcProgress(e.pageX - rect.left, pBarRef.value.offsetWidth)
        )
      }
    }

    function stopDrag() {
      dragging.value = false
      window.removeEventListener('mousemove', doDrag)
    }

    function calcProgress(offset: number, offsetWidth: number) {
      const progress = (offset * 100) / offsetWidth
      if (progress > 100) {
        return 100
      } else if (progress < 0) {
        return 0
      }
      return progress
    }

    return {
      pBarRef,
      variantBgColor,
      variantTextColor,
      handleProgressChange,
      progressStyle,
      startDrag,
      doDrag
    }
  }
})
